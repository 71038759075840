type IProducts = {
  carouselName: string;
  products: any;
}

type ICarouselRepository = {
  _data: {
    [carouselName in string]: any;
  },
  cache: (props: IProducts) => void,
  get: (carousel?: string) => [],
}
export const carouselProductsRepository: ICarouselRepository = {
  _data: {},
  cache: ({ carouselName, products }: IProducts) => {
    carouselProductsRepository._data[carouselName] = products
  },
  get: (carouselName) => {
    if (!carouselName) {
      return undefined;
    }
    if (carouselProductsRepository?._data[carouselName]) {
      return (carouselProductsRepository?._data[carouselName])
    }
    return undefined;
  }}