import Icon from "@components/shared/Icon";
import { useContext } from "react";
import { AppContext } from "~/src/libs/context";
import styles from "./styles.module.scss";
import Link from "next/link";
import Dashboard from "~/src/components/organsim/AccountPage/Dashboard";

const User = () => {
  const { setOpenMiniCart, setOpenMenuSearch, setContent, setCurrent } =
    useContext<any>(AppContext);
  return (
    <button
      title="Profile"
      className="p-0 bg-transparent"
      style={{ border: 0 }}
    >
      <Link href="/account/dashboard">
        <a
          onClick={() => {
            setOpenMenuSearch(false);
            setOpenMiniCart(false);
            setContent(<Dashboard />);
            setCurrent("dashboard")
          }}
          className={styles.accountIcon}
          aria-label="Go to user account"
        >
          <Icon
            name="account"
            width={28}
            height={28}
          />
        </a>
      </Link>
    </button>
  );
};

export default User;
