import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getCookie, hasCookie, setCookie } from "cookies-next";
import { useRouter } from "next/router";
import { FILTERS_ORDERS } from "@src/constants";
import useClient from "~/src/server/utils/client";
import { notification } from "antd";
import axios from "axios";
const AppContext = React.createContext({});
const AppProvider = ({ children }) => {
  const router = useRouter();
  const isSessionValid =
    process?.env?.NEXT_PUBLIC_APP_ENV === "development"
      ? "staging_valid_session"
      : "valid_session";
  let isGuest =
    getCookie(isSessionValid) === "" || getCookie(isSessionValid) === undefined
      ? true
      : false;

  let isCompareActive = getCookie("isCompareActive");
  let productsSku = hasCookie("compareSkus")
    ? getCookie("compareSkus")
    : JSON.stringify([]);
  const [showZipCodeDialog, setShowZipCodeDialog] = useState(false);
  const [shoppingListLoading, setShoppingListLoading] = useState(true);
  const [sponsoredProducts, setSponsoredProducts] = useState();
  const [isSponsoredLoading, setIsSponsoredLoading] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [openMenuSearch, setOpenMenuSearch] = useState(false);
  const [getNextPage, setGetNextPage] = useState(false);
  const [sortByVal, setSortByVal] = useState("Relevance");
  const [sortKey, setSortKey] = useState("relevance");
  const [showBonuses, setShowBonuses] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [requestedFilter, setRequestedFilter] = useState({});
  const [redeemAmountChanged, setRedeemAmountChanged] = useState(false);
  const [isCouponAdded, setIsCouponAdded] = useState(false);
  const [storeSearchValue, setStoreSearchValue] = useState();
  const [couponsName, setCouponsName] = useState("");
  const [isOpenMiniCart, setOpenMiniCart] = useState(false);
  const [showNonActiveProduct, setShowNonActiveProduct] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [recentSearch, setRecentSearch] = useState();
  const [productListInfo, setProductListInfo] = useState();
  const [creditCardList, setCreditCardList] = useState();
  const [selectedFiltersItem, setSelectedFiltersItem] = useState([]);
  const [guestUser, setGuestUser] = useState(isGuest);
  const [isMainMenuOpen, setMainMenuOpen] = useState(false);
  const [isCategoryMenuOpen, setCategoryMenuOpen] = useState(false);
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [content, setContent] = useState(<></>);
  const [searchedData, setSearchedData] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [orderID, setOrderID] = useState();
  const [accountActiveTab, setAccountActiveTab] = useState();
  const [businessMenu, setBusinessMenu] = useState();
  const [categoryMenu, setCategoryMenu] = useState();
  const [brandsList, setBrandsList] = useState();
  const [isVendor, setIsVendor] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [showProductCompare, setShowProductCompare] = useState(isCompareActive);
  const [compareProductsSku, setCompareProductsSku] = useState(
    JSON?.parse(productsSku)
  );
  const [userDetails, setUserDetails] = useState();
  const [compareProductDetails, setCompareProductDetails] = useState([]);
  const [isTyping, setTyping] = useState(0);
  const [helpCenterQuery, setHelpCenterQuery] = useState(
    Object.keys(router?.query)?.[0]
  );
  const [customerGroup, setCustomerGroup] = useState();
  const [query, setQuery] = useState(router?.query?.["slug"]?.[0]);
  const [cart, setCart] = useState();
  const [canAccessOrderPage, setCanAccessOrderPage] = useState(false);
  const [productFiltersMenu, setProductFiltersMenu] = useState();
  const [priceFilterProduct, setPriceFilterProduct] = useState([]);
  const [minPriceFilterValue, setMinPriceFilterValue] = useState();
  const [maxPriceFilterValue, setMaxPriceFilterValue] = useState();
  const [minPriceUnitFilterValue, setMinPriceUnitFilterValue] = useState();
  const [maxPriceUnitFilterValue, setMaxPriceUnitFilterValue] = useState();
  const [googlePlacesScript, setGooglePlacesScript] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [completeProfileLink, setCompleteProfileLink] = useState(
    "/?complete_profile=true"
  );
  const [loadingShippingAddresses, setLoadingShippingAddresses] =
    useState(false);
  const [redeemAmount, setRedeemAmount] = useState(0);
  const [redeemPlaceOrderAmount, setRedeemPlaceOrderAmount] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [billingAddress, setBillingAddress] = useState({});
  const [loadingBillingAddresses, setLoadingBillingAddresses] = useState(false);
  const [shoppingList, setShoppingList] = useState([]);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [totalResultsCount, setTotalResultsCount] = useState(0);
  const [sessionError, setSessionError] = useState();
  const [userEmail, setUserEmail] = useState();
  const [sessionExpiredOpened, setSessionExpiredOpened] = useState(false);
  const [customerType, setCustomerType] = useState();
  const [customerStates, setCustomerStates] = useState();
  const [defaultPostZip, setDefaultPostZip] = useState("");
  const [userID, setUserId] = useState("");
  const [customerStatus, setCustomerStatus] = useState({});
  const [addToWishListOpened, setAddToWishListOpened] = useState(false);
  const [vendorID, setVendorID] = useState();
  const [menuData, setMenuData] = useState({});
  const [current, setCurrent] = useState(
    router?.query?.[""] ? router?.query?.[""] : "dashboard"
  );
  const [postZip, setPostZip] = useState("");
  const [shoppingListUpdated, setShoppingListUpdated] = useState(false);
  const [quantityValue, setQuantityValue] = useState(1);
  const [commonStates, setCommonStates] = useState([{ label: "", value: "" }]);
  const [countries, setCountries] = useState();
  const commonCountries = [{ label: "US", value: "US" }];
  const [isStatusLoading, setIsStatusLoading] = useState(true);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [addressId, setAddressId] = useState();
  const client = useClient({ setIsSessionExpired });

  useEffect(() => {
    let couponName = "";
    cart?.coupon_applications?.forEach(function (item) {
      couponName += ` ${item?.coupon?.code} `;
    });
    setCouponsName(couponName);
  }, [cart]);
  const getCartItems = async () => {
    setLoadingCart(true);
    try {
      let url = `/api/ERP/cart`;
      if (defaultPostZip && !customerStatus?.has_addresses) {
        url += `?post_zip=${defaultPostZip}`;
      }
      const res = await client.basic
        .get(url, {
          headers: { sessionId: getCookie("sessionid") },
        })
        .then((res) => {
          setCart(res?.data);
          setLoadingCart(false);
        })
        .catch((e) => {
          setLoadingCart(false);
        });
    } catch (e) {
      setLoadingCart(false);
    }
  };
  const fetchCustomerType = async (id) => {
    try {
      let response = await client.basic.get(`/api/ERP/customer/types/${id}`, {
        headers: { sessionId: getCookie("sessionid") },
      });
      setCustomerType(response?.data);
    } catch (e) {
      console.log(e);
    }
  };
  const getShippingAddresses = async () => {
    let response;
    try {
      response = await axios.get(`/api/ERP/customer/shippingAddress`, {
        headers: { sessionId: getCookie("sessionid") },
      });
      setShippingAddresses(response?.data);
      const uniqueStates = [];
      response?.data?.forEach((location) => {
        const state = location.state;
        if (!uniqueStates.includes(state)) {
          uniqueStates.push(state);
          setCustomerStates(uniqueStates);
        }
      });
    } catch (error) {}
  };
  const getBillingAddress = async () => {
    setLoadingBillingAddresses(true);
    try {
      let response = await client.basic.get(
        "/api/ERP/customer/billingAddress",
        {
          headers: { sessionid: getCookie("sessionid") },
        }
      );
      setBillingAddress(response?.data || {});
    } catch (e) {
      setLoadingShippingAddresses(false);
    }
    setLoadingBillingAddresses(false);
  };

  const getCartCount = async () => {
    try {
      let response = await client.basic.get("/api/ERP/cart/cartCount", {
        headers: { sessionid: getCookie("sessionid") },
      });
      setCartCount(response?.data?.pallet_count);
    } catch (e) {
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 4,
      });
    }
  };

  const getAllOrders = async () => {
    let response;
    try {
      response = await client.basic.get(`/api/ERP/orders/all`, {
        headers: { sessionId: getCookie("sessionid") },
      });
      let monthItems = [];
      Object.entries(response?.data?.filters?.[0]?.choices)?.map((item) =>
        monthItems?.push({ label: item[0], key: item[1].value })
      );
      let monthFilterItem = {
        header: { label: "Month", key: "Month" },
        items: monthItems,
      };
      FILTERS_ORDERS.splice(1, 0, monthFilterItem);
    } catch (error) {
      console.log(error);
    }
  };
  const getShoppingList = async () => {
    setShoppingListLoading(true);
    try {
      const res = await client.basic
        .get("/api/ERP/customer/wishlist", {
          headers: { sessionId: getCookie("sessionid") },
        })
        .then((res) => {
          setShoppingList(res?.data?.length ? res?.data : []);
          setShoppingListLoading(false);
        })
        .catch((err) => {
          setShoppingListLoading(false);
        });
    } catch (error) {
      setShoppingListLoading(false);
    }
  };
  const getCustomerStatus = async () => {
    setIsStatusLoading(true);
    try {
      let response = await client.basic.get("/api/ERP/customer/status", {
        headers: { sessionid: getCookie("sessionid") },
      });
      setCustomerStatus(response?.data);
      setCookie("profileCompleted", response?.data?.profile_completed, {
        sameSite: "strict",
        secure: true,
      });
      setCustomerGroup(response?.data?.customer_groups);
      setUserId(response?.data?.user_id);
      fetchCustomerType(response?.data?.user_id);
      setIsVendor(response?.data?.is_vendor);
      response?.data?.is_vendor
        ? setCompleteProfileLink(process?.env?.NEXT_PUBLIC_VENDOR_URL)
        : setCompleteProfileLink("/?complete_profile=true");
      setVendorID(response?.data?.vendor_id);
    } catch (e) {
      console.log(e);
    }
    setIsStatusLoading(false);
  };
  const getCreditCards = async () => {
    try {
      let response = await client.basic.get("/api/ERP/customer/creditCard", {
        headers: { sessionId: getCookie("sessionid") },
      });
      setCreditCardList(response?.data);
    } catch (e) {
      console.error("Fetch credit cards error:", e);
    }
  };
  useEffect(() => {
    if (customerStatus?.email_verified) {
      getCreditCards();
    }
  }, [customerStatus?.email_verified]);

  const getCustomerData = async () => {
    let data;
    try {
      data = await client.basic.get("/api/ERP/customer/profile", {
        headers: { sessionid: getCookie("sessionid") },
      });
      setUserEmail(data?.data?.email);
      setPostZip(data?.data?.post_zip);
      setUserDetails(data?.data);
    } catch (e) {
      console.log(e);
    }
  };
  const getCommonStates = async () => {
    try {
      let response = await client.basic.get("/api/ERP/common/states", {
        headers: { sessionid: getCookie("sessionid") },
      });
      let statesList = response?.data?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_abrv,
        };
      });
      setCommonStates(statesList);
    } catch (e) {
      console.log(e);
    }
  };
  const getCountries = async () => {
    try {
      let response = await client.basic.get("/api/ERP/common/countries", {
        headers: { sessionid: getCookie("sessionid") },
      });
      setCountries(response?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!guestUser) {
      getShippingAddresses();
      if (router?.asPath !== "/account/myOrders") {
        getAllOrders();
      }
      getCustomerData();
      getCommonStates();
      getBillingAddress();
      getCustomerStatus();
      getCountries();
    }
    else{
      setIsStatusLoading(false)
    }
  }, []);

  useEffect(() => {
    if (!guestUser && shoppingListUpdated) {
      getShoppingList();
    }
  }, [shoppingListUpdated]);
  useEffect(() => {
    if (addToWishListOpened) {
      getShoppingList();
      setAddToWishListOpened(false);
    }
  }, [addToWishListOpened]);
  useEffect(() => {
    if (guestUser && customerStatus?.is_authenticated) {
      const isSessionValid =
        process?.env?.NEXT_PUBLIC_APP_ENV === "development"
          ? "staging_valid_session"
          : "valid_session";
      const ePalletDomain = process?.env?.NEXT_PUBLIC_PARENT_DOMAIN;
      removeCookies(isSessionValid, {
        path: "/",
        domain: ePalletDomain,
      });
      setCookie(isSessionValid, "valid", {
        path: "/",
        httpOnly: true,
        secure: true,
        domain: ePalletDomain,
      });
      window.location.href = "/";
      setGuestUser(false);
    }
  }, [customerStatus]);

  const [showNoOffersModal, setShowNoOffersModal] = useState(false);

  return (
    <AppContext.Provider
      value={{
        recentSearch,
        setRecentSearch,
        current,
        setCurrent,
        content,
        setContent,
        guestUser,
        setGuestUser,
        searchedData,
        setSearchedData,
        query,
        setQuery,
        cart,
        setCart,
        shippingAddresses,
        getShippingAddresses,
        showProductCompare,
        setShowProductCompare,
        compareProductsSku,
        setCompareProductsSku,
        compareProductDetails,
        setCompareProductDetails,
        isTyping,
        setTyping,
        creditCardList,
        setCreditCardList,
        isSearchLoading,
        setIsSearchLoading,
        helpCenterQuery,
        setHelpCenterQuery,
        googlePlacesScript,
        setGooglePlacesScript,
        setLoadingCart,
        loadingCart,
        accountActiveTab,
        setAccountActiveTab,
        isMainMenuOpen,
        setMainMenuOpen,
        setShoppingList,
        shoppingList,
        setSelectedFiltersItem,
        selectedFiltersItem,
        loadingShippingAddresses,
        isViewDetails,
        setIsViewDetails,
        setOrderID,
        orderID,
        setBusinessMenu,
        businessMenu,
        categoryMenu,
        setCategoryMenu,
        brandsList,
        setBrandsList,
        priceFilterProduct,
        setPriceFilterProduct,
        toggleLoader,
        setToggleLoader,
        sessionExpiredOpened,
        setSessionExpiredOpened,
        setProductFiltersMenu,
        productFiltersMenu,
        customerStatus,
        shoppingListUpdated,
        setShoppingListUpdated,
        setCustomerType,
        customerType,
        customerStates,
        userEmail,
        postZip,
        setPostZip,
        setDefaultPostZip,
        defaultPostZip,
        setMenuData,
        menuData,
        dataLoading,
        setDataLoading,
        userID,
        setUserId,
        productListInfo,
        setProductListInfo,
        minPriceFilterValue,
        setMinPriceFilterValue,
        maxPriceFilterValue,
        setMaxPriceFilterValue,
        setQuantityValue,
        quantityValue,
        openMenuSearch,
        setOpenMenuSearch,
        requestedFilter,
        setRequestedFilter,
        isOpenMiniCart,
        isCategoryMenuOpen,
        setCategoryMenuOpen,
        setOpenMiniCart,
        setCustomerGroup,
        customerGroup,
        commonStates,
        countries,
        commonCountries,
        billingAddress,
        getBillingAddress,
        setLoadingBillingAddresses,
        loadingBillingAddresses,
        inputValue,
        setInputValue,
        isStatusLoading,
        shoppingListLoading,
        setShoppingListLoading,
        sortByVal,
        setSortByVal,
        setSortKey,
        sortKey,
        getCustomerStatus,
        getCartItems,
        isVendor,
        setIsVendor,
        showNonActiveProduct,
        setShowNonActiveProduct,
        vendorID,
        setVendorID,
        getShoppingList,
        setShowVerifyModal,
        showVerifyModal,
        showBonuses,
        setShowBonuses,
        userDetails,
        setUserDetails,
        showNoOffersModal,
        isCouponAdded,
        setIsCouponAdded,
        setShowNoOffersModal,
        redeemPlaceOrderAmount,
        setRedeemPlaceOrderAmount,
        storeSearchValue,
        redeemAmountChanged,
        setRedeemAmountChanged,
        setStoreSearchValue,
        couponsName,
        setCouponsName,
        canAccessOrderPage,
        setCanAccessOrderPage,
        redeemAmount,
        setRedeemAmount,
        setIsSessionExpired,
        isSessionExpired,
        completeProfileLink,
        setCompleteProfileLink,
        isProductsLoading,
        setIsProductsLoading,
        loadingState,
        setLoadingState,
        getNextPage,
        setGetNextPage,
        addToWishListOpened,
        setAddToWishListOpened,
        totalResultsCount,
        setTotalResultsCount,
        hasNextPage,
        setHasNextPage,
        cartCount,
        setCartCount,
        getCartCount,
        showZipCodeDialog,
        setShowZipCodeDialog,
        minPriceUnitFilterValue,
        setMinPriceUnitFilterValue,
        maxPriceUnitFilterValue,
        setMaxPriceUnitFilterValue,
        addressId,
        setAddressId,
        isSponsoredLoading,
        setIsSponsoredLoading,
        sponsoredProducts,
        setSponsoredProducts,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.object,
};

export { AppContext, AppProvider };
