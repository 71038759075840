import erpAxios from "@src/libs/utils/axiosEpalletERP";
import { OptionsType } from "cookies-next/lib/types";
import router from "next/router";

export interface filterItem {
  header: string;
  item: string;
  isDietaryOrNutrition?: string;
}

export const cleanPageURL = (
  pageUrlString: string,
  excludedQueryParam: string[]
) => {
  const pageUrl = new URL(pageUrlString);
  const pageUrlCopy = new URL(pageUrlString);
  excludedQueryParam?.forEach((query) => {
    if (pageUrlCopy.searchParams.has(query)) {
      pageUrl.searchParams.delete(query);
    }
  });
  return pageUrl.href;
};

export function getCookie(cookieName = "") {
  const name = `${cookieName}=`;
  try {
    const decodedCookie =
      (typeof document === "object" && decodeURIComponent(document.cookie)) ||
      "";
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  } catch (err) {}
  return null;
}

export function setCookie(
  cookieKey = "",
  value: string | null = null,
  expirationTimeInMinute = null,
  options?: OptionsType
) {
  try {
    if (expirationTimeInMinute) {
      const date = new Date();
      date.setTime(date.getTime() + expirationTimeInMinute * 60000);
      const expires = `; expires=${date.toUTCString()}`;
      document.cookie = `${cookieKey}=${value};${expires};path=/; sameSite=${options?.sameSite}; secure=${options?.secure}`;
    } else {
      document.cookie = `${cookieKey}=${value};path=/; sameSite=${options?.sameSite}; secure=${options?.secure}`;
    }
  } catch (err) {}
}

export const erpRequest = (config: any = {}) => {
  const sessionid =
    process?.env?.NEXT_PUBLIC_APP_ENV === "development"
      ? "staging_sessionid"
      : "sessionid";
  config.headers = {
    cookie: `${sessionid}=${config?.sessionId};${
      config?.headers?.cookie || ""
    }`,
    ...config?.headers,
  };
  return erpAxios(config);
};
export const formatPrice = (price: number | string) =>
  `$${Number(Number(price).toFixed(2)).toLocaleString()}`;

export const shippingHoursOption: object[] = [];

const formatTime = (minutes: number, mode?: string) => {
  let h = Math.floor(minutes / 60);
  const m = (minutes % 60) / 60;
  if (mode === "PM" && h < 12) h += 12;
  else if (mode === "AM" && h >= 12) h += 12;
  if (h > 23) h = 0;
  let updatedHours = null;
  if (h < 10) {
    updatedHours = "0" + h;
  }
  return [updatedHours ? updatedHours : h, m * 60 || "00"]
    .filter(Boolean)
    .join(":");
};

for (let i = 60; i <= 750; i = i + 30) {
  shippingHoursOption?.push({
    value: `${formatTime(i, "AM")}:00`,
    label: `${formatTime(i)} AM`,
  });
}
for (let i = 60; i <= 750; i = i + 30) {
  shippingHoursOption?.push({
    value: `${formatTime(i, "PM")}:00`,
    label: `${formatTime(i)} PM`,
  });
}
const compareTime = (a:any, b:any) => {
  return a.value.localeCompare(b.value);
}

shippingHoursOption.sort(compareTime);
export const replaceEmptyOrNull = (obj: any) => {
  const result: any = {};

  for (let key in obj) {
    if (obj[key] === null || obj[key] === "") {
      result[key] = "-";
    } else if (typeof obj[key] === "object") {
      result[key] = replaceEmptyOrNull(obj[key]);
    } else {
      result[key] = obj[key];
    }
  }

  return result;
};

export const haveSimilarItem = (arr1: [], arr2: []) => {
  for (let item of arr1) {
    if (arr2.includes(item)) {
      return true;
    }
  }
  return false;
};
export const areArraysIdentical = (arr1: [], arr2: []) => {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
};
export const getTruckSizesRestrictionsOptions = () => [
  {
    value: "None",
    label: "None",
  },
  {
    value: "40",
    label: "40'",
  },
  {
    value: "48",
    label: "48'",
  },
  {
    value: "53",
    label: "53'",
  },
];

export const getLabelForHeader = (item: any) => {
  const label =
    item?.header === "Brand"
      ? "brands"
      : item?.header === "Certificates filter"
      ? "certificates"
      : item?.header === "Cool school point eligible"
      ? "cool_school_point_eligible"
      : item?.header === "Country of growth"
      ? "country_of_growths"
      : item?.header === "Food"
      ? "is_food"
      : item?.header === "For mixed pallet"
      ? "for_mixed_pallet"
      : item?.header === "Is prototype"
      ? "is_prototype"
      : item?.header === "Vendor name"
      ? "manufacturers"
      : item?.header === "Country of manufacturer"
      ? "country_of_manufacturers"
      : item?.header === "Mfg item"
      ? "mfg_items"
      : item?.header === "Packaging"
      ? "packaging_types"
      : item?.header === "Shipping method"
      ? "shipping_methods"
      : item?.header === "Storage method"
      ? "storage_methods"
      : item?.header === "Unit size"
      ? "unit_sizes"
      : item?.header === "Unit volume"
      ? "unit_volumes"
      : item?.header === "Upc"
      ? "upc"
      : "";
  return label;
};

export const mapFilters = (
  removePrice: boolean,
  productFiltersList: any,
  minPriceFilterValue?: number,
  maxPriceFilterValue?: number,
  maxPriceUnitFilterValue?: number,
  minPriceUnitFilterValue?: number
) => {
  let filterObj: any = {};
  const query = router.query;
  if(!removePrice){
  if (minPriceFilterValue) {
    filterObj.min_delivered_price = minPriceFilterValue;
    router.push(
      {
        query: {
          ...query,
          minPriceFilterValue: minPriceFilterValue,
          slug: router.query.slug,
        },
      },
      undefined,
      { shallow: true }
    );
  }
  if (maxPriceFilterValue) {
    filterObj.max_delivered_price = maxPriceFilterValue;
    router.push(
      {
        query: {
          ...query,
          maxPriceFilterValue: maxPriceFilterValue,
          slug: router.query.slug,
        },
      },
      undefined,
      { shallow: true }
    );
  }
  if (maxPriceUnitFilterValue) {
    filterObj.max_unit_price = maxPriceUnitFilterValue;
    router.push(
      {
        query: {
          ...query,
          maxPriceUnitFilterValue: maxPriceUnitFilterValue,
          slug: router.query.slug,
        },
      },
      undefined,
      { shallow: true }
    );
  }
  if (minPriceUnitFilterValue) {
    filterObj.min_unit_price = minPriceUnitFilterValue;
    router.push(
      {
        query: {
          ...query,
          minPriceUnitFilterValue: minPriceUnitFilterValue,
          slug: router.query.slug,
        },
      },
      undefined,
      { shallow: true }
    );
  }
}else {
  const newQuery = { ...query };
  delete newQuery.minPriceUnitFilterValue;
  delete newQuery.maxPriceUnitFilterValue;
  delete newQuery.minPriceFilterValue;
  delete newQuery.maxPriceFilterValue;
  router.push(
    {
      pathname: router.pathname,
      query: {
        ...newQuery,
        slug: newQuery.slug,
      },
    },
    undefined,
    { shallow: true }
  );
}
  const promo = productFiltersList.find((item: any) => item.header === "promo");
  if (promo) {
    filterObj.has_promo = true;
  }
  const haggle = productFiltersList.find(
    (item: any) => item?.header === "haggle Rewards"
  );
  if (haggle) {
    filterObj.has_haggle_rewards = true;
  }
  const dietaryList = productFiltersList
    .filter((item: any) => item?.isDietaryOrNutrition === "DietaryGroup")
    .map((item: any) => item?.header);
  if (dietaryList?.length) {
    filterObj.diet = dietaryList;
  }
  const nutritionList = productFiltersList
    .filter((item: any) => item?.isDietaryOrNutrition === "NutritionGroup")
    .map((item: any) => item?.header);
  if (nutritionList?.length) {
    filterObj.nutritions = nutritionList;
  }

  productFiltersList.forEach((item: any) => {
    if (
      item?.isDietaryOrNutrition !== "NutritionGroup" &&
      item?.isDietaryOrNutrition !== "DietaryGroup" &&
      item?.header !== "promo" &&
      item?.header !== "haggle Rewards"
    ) {
      const label = getLabelForHeader(item);
      if (item?.item == "true" || item?.item == "false") {
        filterObj[label] = item?.item == "true" ? true : false;
      } else {
        if (filterObj[label]) {
          filterObj[label].push(item?.item);
        } else {
          filterObj[label] = [item?.item];
        }
      }
    }
  });
  return filterObj;
};

export const handleMenuClicked = async (
  e: any,
  setSortByVal: any,
  setSortKey: any,
  getSortByValues: any
) => {
  e?.key === "relevance"
    ? setSortByVal("(Relevance)")
    : e?.key === "delivered_price"
    ? setSortByVal("(Price: Low > High)")
    : e?.key === "-delivered_price"
    ? setSortByVal("(Price: High > Low)")
    : e?.key === "lead_time_days"
    ? setSortByVal("(Lead Time: Low > High)")
    : e?.key === "-lead_time_days"
    ? setSortByVal("(Lead Time: High > Low)")
    : e?.key === "per_unit_delivered_price"
    ? setSortByVal("(Unit Price: Low > High)")
    : e?.key === "-per_unit_delivered_price"
    ? setSortByVal("(Unit Price: High > Low)")
    : setSortByVal("(Most Popular)");
  const key = e?.key;
  setSortKey(key);
  window?.dataLayer?.push({
    event: "sort_by",
    sort_by_click: key,
  });
  getSortByValues(key);
};

export const addFilterToURL = (
  productFiltersList: filterItem[],
  minPriceFilterValue: number,
  maxPriceFilterValue: number,
  maxPriceUnitFilterValue: number,
  minPriceUnitFilterValue: number
) => {
  const { query } = router;
  const updatedQuery: any = {};
  if (minPriceFilterValue > 0) {
    updatedQuery.minPriceFilterValue = minPriceFilterValue;
  } else {
    delete updatedQuery.minPriceFilterValue;
  }
  if (maxPriceFilterValue > 0) {
    updatedQuery.maxPriceFilterValue = maxPriceFilterValue;
  } else {
    delete updatedQuery.maxPriceFilterValue;
  }
  if (maxPriceUnitFilterValue > 0) {
    updatedQuery.maxPriceUnitFilterValue = maxPriceUnitFilterValue;
  } else {
    delete updatedQuery.maxPriceUnitFilterValue;
  }
  if (minPriceUnitFilterValue > 0) {
    updatedQuery.minPriceUnitFilterValue = minPriceUnitFilterValue;
  } else {
    delete updatedQuery.minPriceUnitFilterValue;
  }
  productFiltersList?.forEach((element) => {
    if (
      element?.isDietaryOrNutrition !== "DietaryGroup" &&
      element?.isDietaryOrNutrition !== "NutritionGroup"
    ) {
      if (element.header in updatedQuery) {
        if (!Array.isArray(updatedQuery[element.header])) {
          updatedQuery[element.header] = [updatedQuery[element.header]];
        }
        if (!updatedQuery[element.header].includes(element.item)) {
          updatedQuery[element.header].push(element.item);
        }
      } else {
        updatedQuery[element.header] = [element.item];
      }
    } else {
      const groupName =
        element?.isDietaryOrNutrition === "DietaryGroup"
          ? "diet"
          : "nutritions";
      if (groupName in updatedQuery) {
        if (!Array.isArray(updatedQuery[groupName])) {
          updatedQuery[groupName] = [updatedQuery[groupName]];
        }
        if (!updatedQuery[groupName].includes(element.header)) {
          updatedQuery[groupName].push(element.header);
        }
      } else {
        updatedQuery[groupName] = [element.header];
      }
    }
  });

  router.push({ pathname: router.pathname, query: { ...updatedQuery, slug: router.query.slug }, }, undefined, {
    shallow: true,
  });
};

export const removeUnselectedFiltersFromURL = (
  productFiltersList: filterItem[],
  router: any
) => {
  const updatedQuery: any = {};
  productFiltersList?.forEach((element) => {
    if (
      element?.isDietaryOrNutrition !== "DietaryGroup" &&
      element?.isDietaryOrNutrition !== "NutritionGroup"
    ) {
      if (element.header in updatedQuery) {
        if (!Array.isArray(updatedQuery[element.header])) {
          updatedQuery[element.header] = [updatedQuery[element.header]];
        }
        if (!updatedQuery[element.header].includes(element.item)) {
          updatedQuery[element.header].push(element.item);
        }
      } else {
        updatedQuery[element.header] = [element.item];
      }
    } else {
      const groupName =
        element?.isDietaryOrNutrition == "DietaryGroup" ? "diet" : "nutritions";
      if (groupName in updatedQuery) {
        if (!Array.isArray(updatedQuery[groupName])) {
          updatedQuery[groupName] = [updatedQuery[groupName]];
        }
        if (!updatedQuery[groupName].includes(element.header)) {
          updatedQuery[groupName].push(element.header);
        }
      } else {
        updatedQuery[groupName] = [element.header];
      }
    }
  });
  if (router.query.minPriceFilterValue !== undefined) {
    updatedQuery.minPriceFilterValue = router.query.minPriceFilterValue;
  }
  if (router.query.maxPriceFilterValue !== undefined) {
    updatedQuery.maxPriceFilterValue = router.query.maxPriceFilterValue;
  }
  if (router.query.minPriceUnitFilterValue !== undefined) {
    updatedQuery.minPriceUnitFilterValue = router.query.minPriceUnitFilterValue;
  }
  if (router.query.maxPriceUnitFilterValue !== undefined) {
    updatedQuery.maxPriceUnitFilterValue = router.query.maxPriceUnitFilterValue;
  }
  router.push(
    {
      query: { ...updatedQuery, slug: router.query.slug },
    },
    undefined,
    { shallow: true }
  );
};

export const getFiltersFromURL = (query: any) => {
  const filters = [];

  for (const key in query) {
    const value: any = query[key];

    if (key === "diet" || key === "nutritions") {
      if (Array.isArray(value)) {
        value?.forEach((item: any) => {
          filters?.push({
            header: item,
            item: "true",
            isDietaryOrNutrition:
              key === "diet" ? "DietaryGroup" : "NutritionGroup",
          });
        });
      }
      else{
        filters?.push({
          header: value,
          item: "true",
          isDietaryOrNutrition:
          key === "diet" ? "DietaryGroup" : "NutritionGroup",
        });
      }
    } else {
      if (Array.isArray(value)) {
        value?.forEach((item: any) => {
          const isDietaryOrNutrition =
            key === "haggle Rewards" || key === "promo"
              ? "Bonuses and Exclusives"
              : undefined;

          filters?.push({
            header: key,
            item,
            isDietaryOrNutrition,
          });
        });
      } else {
        const isDietaryOrNutrition =
          key === "haggle Rewards" || key === "promo"
            ? "Bonuses and Exclusives"
            : undefined;

        filters?.push({
          header: key,
          item: value,
          isDietaryOrNutrition,
        });
      }
    }
  }
  return filters;
};
