import styles from "./styles.module.scss";
import SearchResult from "./SearchResult";
import classnames from "classnames";
import SearchBox from "./SearchBox";
import { useState, useEffect, useContext } from "react";
import { AppContext } from "~/src/libs/context";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize.js";
import Overlay from "../../../Overlay";
const Search = ({ isSearchPage, getItemId, searchRef, open, setOpen }: any) => {
  const { setTyping, openMenuSearch, setOpenMenuSearch } =
    useContext<any>(AppContext);
  const [openMenu, setOpenMenu] = useState(open || false);
  const [isTrending, setIsTrending] = useState(true);
  const [data, setData] = useState([]);
  const windowWidth = useWindowResize();
  useEffect(() => {
    if (typeof open === "boolean" && open) {
      showMenu();
    } else {
      closeMenu();
    }
  }, [open]);

  const showMenu = () => {
    setOpenMenu(true);
    if (windowWidth <= 993) {
      setOpenMenuSearch(true);
    } else {
      setOpenMenuSearch(false);
    }

    setOpen instanceof Function && setOpen(true);
  };
  const closeMenu = () => {
    setTyping(0);
    setOpenMenu(false);
    setOpenMenuSearch(false);
    setOpen instanceof Function && setOpen(false);
    document.body.style.overflow = "auto";
  };

  const isTrendingHandler = (trending: boolean) => {
    setIsTrending(trending);
  };

  return (
    <>
      {!isSearchPage && openMenu && <Overlay closeMenu={closeMenu} />}
      <div
        className={classnames(
          styles.searchModal,
          getItemId ? styles.customWidth : ""
        )}
      >
        <SearchBox
          closeMenu={closeMenu}
          showMenu={showMenu}
          openMenu={openMenu}
          setData={setData}
          data={data}
          isSearchPage={isSearchPage}
          isComparePage={getItemId ? true : false}
          searchRef={searchRef}
          isTrendingHandler={isTrendingHandler}
        />
        <div className={styles.desktopSearch}>
          {openMenu && (
            <SearchResult
              getItemId={getItemId}
              setData={setData}
              searchResults={data}
              openMenu
              closeMenu={closeMenu}
              showMenu={showMenu}
              isTrending={isTrending}
            />
          )}
        </div>
        <div className={styles.mobileSearch}>
          {openMenu && openMenuSearch && (
            <SearchResult
              getItemId={getItemId}
              setData={setData}
              searchResults={data}
              openMenu
              closeMenu={closeMenu}
              showMenu={showMenu}
              isTrending={isTrending}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Search;
