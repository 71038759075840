import { FormProps } from "./types";
import { Modal, Form, Row, Col, Space, message, notification } from "antd";
import { PdpContext } from "@src/libs/context/pdp";
import { useContext, useEffect, useState, useRef } from "react";
import { FormFields, RecipientName, RecipientEmail } from "./FormFields";
import classnames from "classnames";
import CustomInput from "@components/shared/Fields";
import styles from "@components/shared/requestForm/styles.module.scss";
import Button from "@components/shared/Button";
import Icon from "@components/shared/Icon";
import axios from "axios";
import useCopyToClipboard from "@components/shared/CopyToClipboard";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import ReCAPTCHA from "react-google-recaptcha";
import { getCookie } from "~/src/server/utils/commonUtils";
import { getText } from "~/src/libs/resources";
import { AppContext } from "~/src/libs/context";
import Selector from "~/src/modules/MixedPallet/selector";
import { useTypedSelector } from "~/src/libs/utils/useTypedSelector";

const ShareProductModal = ({
  isModalOpen,
  setIsModalOpen,
  isProductShare,
  shoppingListDetails,
}: FormProps) => {
  const { userDetails } = useContext<any>(AppContext);
  const [productTitle, setProductTitle] = useState("");
  const { productInfo, attributeGroups } = useContext<any>(PdpContext);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [value, copy] = useCopyToClipboard();
  const [messageApi, contextHolder] = message.useMessage();
  const [pageUrl, setPageUrl] = useState("");
  const [recaptcha, setRecaptcha] = useState<any>("");
  const googleRecapcha = useRef<any>();
  const [sendShareStatus, setSendShareStatus] = useState(false);

  const { productInfo: productInfoMixed } = useTypedSelector(
    Selector.getMixedPallet
  );

  const onSubmitWithReCAPTCHA = async (values: any) => {
    const token = await googleRecapcha.current.executeAsync();
    onFinish(values, token);
  };

  const onFinish = async (values: any, token: any) => {
    setSendShareStatus(true);
    const recipients_array: any = [];
    values?.recipients?.map((value: any) => {
      recipients_array.push({
        contact_name: value?.contact_name,
        email: value?.email,
      });
    });

    const data = {
      comments: values?.comments !== undefined ? values?.comments : "",
      email: values?.email,
      contact_name: values?.name,
      receivers: recipients_array,
      recaptcha: token,
    };
    if (isProductShare) {
      try {
        await axios
          .post(
            `/api/ERP/customer/product/${
              productInfo?.erpProductData?.slug ||
              productInfoMixed?.erpProductData?.slug
            }/sendEmail`,
            {
              data,
            }
          )
          .then((res) => {
            window?.dataLayer?.push({
              event: "share",
              method: "Email",
              content_type: "Product",
              item_id:
                productInfo?.erpProductData?.slug?.toString() ||
                productInfoMixed?.erpProductData?.slug?.toString(),
            });
            setShowSuccessModal(true);
            setTimeout(() => {
              setShowSuccessModal(false);
            }, 3000);
            setIsModalOpen(false);
            setSendShareStatus(false);
          })
          .catch((err) => {
            notification.error({
              message: getText().General.Messages.SomethingWrongTryAgain,
              duration: 4,
            });
            setSendShareStatus(false);
          });
      } catch (e) {
        setSendShareStatus(false);
      }
    } else {
      try {
        await axios
          .post(
            `/api/ERP/customer/wishlist/${shoppingListDetails?.uid}/sendEmail`,
            {
              data,
              headers: { sessionId: getCookie("sessionid") },
            }
          )
          .then((res) => {
            window?.dataLayer?.push({
              event: "share",
              method: "Email",
              content_type: "Wishlist",
              item_id: shoppingListDetails?.uid?.toString(),
            });
            setShowSuccessModal(true);
            setTimeout(() => {
              setShowSuccessModal(false);
            }, 3000);
            setIsModalOpen(false);
            setSendShareStatus(false);
          })
          .catch((err) => {
            notification.error({
              message: getText().General.Messages.SomethingWrongTryAgain,
              duration: 4,
            });
            setSendShareStatus(false);
          });
      } catch (error) {
        setSendShareStatus(false);
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSharing = (sharingVia: string, productInfo: any) => {
    window?.dataLayer?.push({
      event: "share",
      method: sharingVia,
      content_type: isProductShare ? "Product" : "Wishlist",
      item_id: isProductShare
        ? productInfo?.erpProductData?.slug?.toString() ||
          productInfoMixed?.erpProductData?.slug?.toString()
        : shoppingListDetails?.uid?.toString(),
    });
  };
  if (isProductShare) {
    useEffect(() => {
      setPageUrl(window.location.href);
      setProductTitle(
        productInfo?.erpProductData?.name ||
          productInfoMixed?.erpProductData?.name
      );
    }, [productInfo, attributeGroups, productInfoMixed]);
  } else {
    useEffect(() => {
      setPageUrl(
        `${process.env.NEXT_PUBLIC_APP_URL}/shared/wishlist/${shoppingListDetails.uid}`
      );
    }, []);
  }

  const pageUrlCopy = () => {
    copy(pageUrl);
    messageApi.open({
      type: "success",
      content: "Copied to clipboard!",
    });
  };
  const onChange = (value: any) => {
    setRecaptcha(value);
  };
  return (
    <>
      {isModalOpen && (
        <ReCAPTCHA
          sitekey={process.env.GOOGLE_RECAPCHA_KEY as any}
          size="invisible"
          ref={googleRecapcha}
          onChange={onChange}
          className={styles["recaptcha-container"]}
        />
      )}
      <Modal
        title={isProductShare ? productTitle : shoppingListDetails.name}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        className={classnames(styles.modal, styles.shareModal)}
        zIndex={1000}
      >
        <Form
          name="Share Product"
          initialValues={{
            email: userDetails?.email,
            name: userDetails?.contact_name,
            recipients: [""],
          }}
          preserve={false}
          autoComplete="off"
          onFinish={onSubmitWithReCAPTCHA}
          className={classnames("py-8 px-10", styles.shareForm)}
        >
          {FormFields?.map((field, index) => (
            <Form.Item
              key={index}
              name={field?.name}
              className={styles.formItem}
              rules={field?.rules}
              hasFeedback={field?.name === "comments" ? false : true}
            >
              <CustomInput
                placeholder={`${field?.placeHolder}${
                  field?.required ? "*" : ""
                }`}
                className={classnames("my-3 py-4")}
              />
            </Form.Item>
          ))}

          <Form.List name="recipients">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    align="baseline"
                    className={styles.recipientDetails}
                  >
                    <Form.Item
                      {...restField}
                      name={[`${name}`, "contact_name"]}
                      validateTrigger={["onChange", "onBlur"]}
                      hasFeedback
                      className={styles.formItem}
                      rules={[
                        {
                          required: true,
                          message: "Recipient name is required",
                        },
                      ]}
                    >
                      <CustomInput
                        placeholder={`${RecipientName?.placeHolder}${
                          RecipientName?.required ? "*" : ""
                        }`}
                        className={classnames("my-3 py-4")}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "email"]}
                      validateTrigger={["onChange", "onBlur"]}
                      hasFeedback
                      className={styles.formItem}
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Please input a valid email.",
                        },
                      ]}
                    >
                      <CustomInput
                        placeholder={`${RecipientEmail?.placeHolder}${
                          RecipientEmail?.required ? "*" : ""
                        }`}
                        className={classnames("my-3 py-4")}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <div className="mb-10">
                        <Button
                          onClick={() => remove(name)}
                          theme="link"
                          hasIcon={true}
                          className={styles.removeBtn}
                          icon={<Icon name="cross" width={24} height={24}/>}
                        ></Button>
                      </div>
                    ) : null}
                  </Space>
                ))}

                <Form.Item>
                  <Row>
                    <Col>
                      <Button
                        theme="link"
                        onClick={add}
                        className={classnames(
                          "text-oxfordBlue ms-2",
                          styles.AddAnotherRecipient
                        )}
                        icon={<Icon name="add" width={20} height={20} />}
                        hasIcon={true}
                      >
                        Add Another Recipient
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button
              theme="primary"
              htmlType="submit"
              className={classnames(
                styles.requestBttn,
                "py-12 font-bold-base mt-4"
              )}
              loading={sendShareStatus}
            >
              Send Link by Email
            </Button>
          </Form.Item>
        </Form>

        <>
          <Row>
            <Col
              span={24}
              className={classnames(styles.socialSharingSection, "py-14")}
            >
              <FacebookShareButton
                className={classnames(styles.socialShareButton, "me-4")}
                url={pageUrl}
                quote="Share this item"
                onClick={() => handleSharing("FaceBook", productInfo)}
              >
                <Icon
                  name="facebook-icon"
                  width={20}
                  height={20}
                  color="#fff"
                />
              </FacebookShareButton>
              <TwitterShareButton
                className={classnames(styles.socialShareButton, "me-4")}
                url={pageUrl}
                onClick={() => handleSharing("Twitter", productInfo)}
              >
                <Icon name="twitter" width={20} height={20} color="#fff" />
              </TwitterShareButton>
              <LinkedinShareButton
                className={classnames(styles.socialShareButton)}
                onClick={() => handleSharing("Linkedin", productInfo)}
                url={pageUrl}
              >
                <Icon name="linkedin" width={20} height={20} color="#076ddc" />
              </LinkedinShareButton>
            </Col>
          </Row>
          <Row className={`${styles.shareModalFooter}`}>
            <Col>
              <span className="font-regular-md">
                Share via a {contextHolder}
                <Button
                  theme="link"
                  onClick={() => pageUrlCopy()}
                  className="text-oxfordBlue ms-2"
                  disabled={false}
                >
                  public link
                </Button>
              </span>
            </Col>
          </Row>
        </>
      </Modal>
      <Modal open={showSuccessModal} footer={null} closable={false}>
        <div className={styles.successModal}>
          <Icon name="check" color="darkBlue" width={150} height={100} />
          <div className="font-medium-h6 text-oxfordBlue mt-2">
            Your {isProductShare ? "product" : "shopping list"} has been shared!
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShareProductModal;
