import { useState, useEffect, useContext } from "react";
import SearchInput from "@components/shared/Fields";
import styles from "../styles.module.scss";
import SearchIcon from "@assets/icons/search.svg";
import Icon from "@components/shared/Icon";
import CustomButton from "@components/shared/Button";
import Search from "@assets/icons/search.svg";
import { useRouter } from "next/router";
import { AppContext } from "../../../../../../libs/context";
import { notification } from "antd";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize.js";
import useProductRestriction from "~/src/utilities/hooks/useProductRestriction";
import { getText } from "~/src/libs/resources";
const SearchBox: React.FC<any> = ({
  showMenu,
  closeMenu,
  getItemId,
  view,
  setData,
  data,
  isSearchPage,
  isComparePage,
  searchRef,
  isTrendingHandler,
}) => {
  const {
    setSearchedData,
    setTyping,
    isTyping,
    setDataLoading,
    setOpenMenuSearch,
    setOpenMiniCart,
    openMenuSearch,
    inputValue,
    vendorID,
    setInputValue,
    shippingAddresses,
    customerStatus,
    customerGroup,
    userEmail,
    showNonActiveProduct,
    isVendor,
  } = useContext<any>(AppContext);
  const router = useRouter();
  const [hasIcon, setHasIcon] = useState(true);
  const [localQuery, setLocalQuery] = useState(inputValue ?? "");
  const windowWidth = useWindowResize();
  let defaultShipping: any;
  shippingAddresses?.forEach((item: any) => {
    if (item["is_default"] === true) {
      defaultShipping = item;
    }
  });
  let algoliaFilters = "";
  if (customerStatus?.customer_type) {
    algoliaFilters =
      "'Visible to':'undefined' OR 'Visible to':'" +
      customerStatus?.customer_type +
      "' AND NOT 'Hidden for':'" +
      customerStatus?.customer_type +
      "'" +
      ` ${
        customerStatus?.is_vendor ? "" : "AND 'For vendors only':'false'"
      } OR 'Is prototype':'true' AND 'archived':'false'`;
  } else {
    algoliaFilters =
      `${customerStatus?.is_authenticated ? "'Visible to':'undefined' AND" : ""} ${
        customerStatus?.is_vendor ? "" : "'For vendors only':'false' OR"
      } 'Is prototype':'true' AND 'archived':'false'`;
  }

  if (defaultShipping?.state && algoliaFilters.length > 0) {
    algoliaFilters =
      algoliaFilters +
      "AND'For states':'undefined' OR'For states':'" +
      defaultShipping?.state +
      "'" +
      ` ${
        customerStatus?.is_vendor ? "" : "AND 'For vendors only':'false'"
      } OR 'Is prototype':'true' AND 'archived':'false'`;
  } else {
    algoliaFilters +=
      "AND 'For states':'undefined'" +
      ` ${
        customerStatus?.is_vendor ? "" : "AND 'For vendors only':'false'"
      } OR 'Is prototype':'true' AND 'archived':'false'`;
  }

  // add cool school filter this is a special case

  let _localQuery = localQuery;
  if (_localQuery.toLowerCase().trim() == "cool school") {
    if (algoliaFilters.length > 0) {
      algoliaFilters =
        "'Cool school point eligible': 'true' AND" + algoliaFilters;
    } else {
      algoliaFilters = "'Cool school point eligible': 'true'";
    }
    _localQuery = "";
  }

  const fetchData = async () => {
    setDataLoading(true);
    await axios
      .post("/api/algolia/getAlgoliaProductsData", {
        data: {
          query: `${_localQuery}`,
          filters: algoliaFilters,
          searchIndex: process.env.NEXT_PUBLIC_ALGOLIA_MAIN_SEARCH_INDEX,
          analytics: localQuery?.trim() ? true : false,
        },
      })
      .then((res: any) => {
        const finalData = useProductRestriction(
          res?.data?.hits,
          customerGroup,
          userEmail,
          defaultShipping,
          showNonActiveProduct,
          isVendor,
          vendorID
        );
        setData(finalData);
      })
      .catch((e) => {
        notification.error({
          message: getText().General.Messages.SomethingWrongTryAgain,
          duration: 4,
        });
      });
    setDataLoading(false);
  };

  const saveSearchedItem = async () => {
    try {
      await axios.post(`/api/ERP/search/saveSearchedItem`, {
        headers: {
          sessionId: getCookie("sessionid"),
        },
        data: { search: localQuery },
      });
    } catch (error: any) {}
  };

  const handelInputChange = (e: any) => {
    setTyping(e.currentTarget.value?.length);
    setLocalQuery(e.currentTarget.value);
    if (e.currentTarget.value.trim() !== "") {
      setInputValue(e.currentTarget.value);
    } else {
      setInputValue("");
    }
    if (e.currentTarget.value) {
      setHasIcon(false);
    } else {
      setHasIcon(true);
    }
  };
  const handelMobileInputChange = (e: any) => {
    setTyping(e?.currentTarget?.value?.length);
    setLocalQuery(e.currentTarget.value);
    setInputValue(e.currentTarget.value);
    if (e.currentTarget.value) {
      setHasIcon(false);
    } else {
      setHasIcon(true);
    }
  };
  const handleEnterKey = (e: any) => {
    window?.dataLayer?.push({
      event: "search",
      search_term: localQuery,
    });
    saveSearchedItem();
    setSearchedData(data);
    isTrendingHandler?.(true);
    e.target.blur();
    if (e.currentTarget.value.trim() !== "") {
      router.push(`/search/${localQuery}`);
    } else {
      setLocalQuery("");
    }
    closeMenu();
    setHasIcon(true);
  };
  const showMobileMenu = () => {
    setOpenMenuSearch(true);
    setOpenMiniCart(false);
    showMenu();
  };
  const closeMobileMenu = () => {
    setOpenMenuSearch(false);
    closeMenu();
  };
  const mobileFocus = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchData();
    setOpenMenuSearch(true);
  };
  const showMenuDesktop = async () => {
    fetchData();
    showMenu();
    if (windowWidth <= 993) {
      setOpenMenuSearch(true);
    } else {
      setOpenMenuSearch(false);
    }
  };
  const onSearch = (e: any) => {
    window?.dataLayer?.push({
      event: "search",
      search_term: localQuery,
    });
    saveSearchedItem();
    setSearchedData(data);
    router.push(`/search/${localQuery}`);
    closeMobileMenu();
  };

  useEffect(() => {
    if (localQuery !== "") {
      fetchData();
    }
  }, [localQuery]);

  useEffect(() => {
    isTrendingHandler?.(!localQuery);
  }, [localQuery]);

  useEffect(() => {
    if (isSearchPage) {
      showMobileMenu();
    }
  }, []);
  const [isClosed, setClosed] = useState(false);
  const closeMenuHandler = () => {
    setClosed(true);
    closeMenu();
  };
  useEffect(() => {
    if (isClosed) {
      searchRef?.current?.blur();
      setClosed(false);
    }
  }, [isClosed]);
  return (
    <>
      {view === "mobile" ? (
        <SearchInput
          type="search"
          onChange={(e: any) => handelMobileInputChange(e)}
          placeholder="Search products or brands"
          hasIcon={hasIcon}
          Icon={SearchIcon}
          allowClear={
            inputValue
              ? {
                  clearIcon: (
                    <Icon classesName="py-4 d-flex" name="mobileCross" />
                  ),
                }
              : null
          }
          className={`bg-white ${styles.mobileSearchField} ${
            isTyping === 0 ? "mb-10" : "mb-8"
          } ${isComparePage ? styles.compareSearchField : ""}`}
          onFocus={mobileFocus}
          autoFocus={true}
          onIconClick={(e: any) => onSearch(e)}
          value={inputValue}
          onPressEnter={(e: any) => onSearch(e)}
          Ref={searchRef ? searchRef : null}
          enterKeyHint="search"
          prefix={true}
        />
      ) : (
        <SearchInput
          type="search"
          onChange={(e: any) => handelInputChange(e)}
          onFocus={showMenuDesktop}
          placeholder="Search products or brands"
          hasIcon={true}
          Icon={SearchIcon}
          allowClear={
            inputValue
              ? { clearIcon: <Icon name="cross" onClick={closeMenuHandler} /> }
              : null
          }
          className={`bg-white ${styles.searchField} ${
            isComparePage ? styles.compareSearchField : ""
          }`}
          onPressEnter={(e: any) => handleEnterKey(e)}
          value={inputValue}
          Ref={searchRef ? searchRef : null}
          prefix={true}
        />
      )}

      {isComparePage ? null : (
        <>
          {!openMenuSearch && !isComparePage ? (
            <CustomButton
              hasIcon={true}
              Icon={Search}
              onClick={showMobileMenu}
              className={`${styles.searchButton}  p-0`}
              aria-label="search"
            />
          ) : (
            <CustomButton
              onClick={closeMobileMenu}
              className={`${styles.closeButton} bg-oxfordBlue p-0`}
              aria-label="search"
            >
              <Icon name="cross" width={18} height={18} color="#ffffff" />
            </CustomButton>
          )}
        </>
      )}
    </>
  );
};

export default SearchBox;
