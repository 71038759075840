export default function useProductRestriction(
  productsList,
  customerGroup,
  userEmail,
  defaultShipping, showNonActiveProduct,isVendor,vendorID
) {
  let finalFilteredPlpProductList = [];
  productsList?.forEach((item) => {
    let isVisibleToCustomerEmail = false;
    let isVisibleToCustomerGroup = false;
    let isVisibleToVendor = true;
    if (
      item["Visible to customer emails"] &&
      item["Visible to customer emails"] != "null"
    ) {
      let visibleToCustomerEmailList1 = item["Visible to customer emails"]
        .replace("[", "")
        .replace("]", "");

      let visibleToCustomerEmailList = visibleToCustomerEmailList1
        .replaceAll('"', "")
        .trim()
        .split(",");

      visibleToCustomerEmailList?.forEach((element) => {
        if (
          element.replace(/\\/g, "").replace('"', "").replace('"', "").trim() ==
          userEmail
        ) {
          isVisibleToCustomerEmail = true;
        }
      });
    } else {
      isVisibleToCustomerEmail = true;
    }
    if (
      item["For customer groups"] &&
      item["For customer groups"] != "undefined"
    ) {
      if (item["For customer groups only to selected"]) {
        const customerGroups = item["For customer groups"]?.split(",");
        customerGroups?.forEach((item) => {
          customerGroup?.forEach((ele) => {
            if (item == ele) {
              isVisibleToCustomerGroup = true;
            }
          });
        });
      } else {
        const customerGroups = item["For customer groups"]?.split(",");
        customerGroups?.forEach((item) => {
          customerGroup?.forEach((ele) => {
            if (item == ele) {
              isVisibleToCustomerGroup = false;
            }
          });
        });
      }
    }
    if(isVendor && showNonActiveProduct === false && item["For vendors only"]){
      isVisibleToVendor = false;
    }
    else if(isVendor && showNonActiveProduct && item["For vendors only"] && (item["Vendor id"] == vendorID || item["Vendor id"] == null)){
        isVisibleToVendor = true;
    }
    else if(isVendor && showNonActiveProduct && item["For vendors only"] && item["Vendor id"] != vendorID){
      isVisibleToVendor = false;
    }
    if (((isVisibleToVendor) &&
      isVisibleToCustomerEmail &&
      (item["For zip from"] === -1 ||
        item["For zip to"] === -1 ||
        (item["For zip from"] <= defaultShipping?.post_zip &&
          item["For zip to"] >= defaultShipping?.post_zip)) &&
      ((item["For customer groups"] != "undefined" &&
        isVisibleToCustomerGroup) ||
        item["For customer groups"] == "undefined")) || item["Is prototype"]
    ) {
      finalFilteredPlpProductList.push(item);
    }
  });
  return finalFilteredPlpProductList;
}
