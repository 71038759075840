import { useContext } from "react";
import { AppContext } from "../context";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import { carouselProductsRepository } from "~/src/components/shared/CustomCarousel/CarouselRepository/cachedCarouselProductData";
import router from "next/router";
import { MixMatchVolumeProductsRepository } from "~/src/components/shared/CustomCarousel/CarouselRepository/fetchMixMatchVolumeProducts";
import { dataSource } from "~/src/components/shared/CustomCarousel/carouselDataSrc";

interface carouselProps {
  setProductData: (arg: []) => void;
  setIsLoading: (arg: boolean) => void;
  productData:[];
  title?:string ;
  algoliaFilters:string;
  productSlug?:string;
  mainCategory?:string;
  subCategory?:string;
  modalShowSet:(arg: boolean)=> void,
}

const useCarousel = ({
  setProductData,
  setIsLoading,
  productData,
  title,
  algoliaFilters,
  productSlug,
  mainCategory,
  subCategory,
  modalShowSet,
}: carouselProps) => {
  const {
    defaultPostZip,
    shippingAddresses,
    showNonActiveProduct,
    isVendor,
    customerStatus,
    guestUser,
    setShowNoOffersModal,
  } = useContext<any>(AppContext);
  let defaultShipping: any;
  shippingAddresses?.forEach((item: any) => {
    if (item["is_default"] === true) {
      defaultShipping = item;
    }
  });
  const fetchCarouselData = async (
    hitsNumber: number,
    filter: string,
    carouselName: string
  ) => {
    await axios
      .post("/api/algolia/getAlgoliaProductsData", {
        data: {
          hitsNumber: hitsNumber,
          pageNumber: 0,
          searchIndex: process.env.NEXT_PUBLIC_ALGOLIA_MAIN_INDEX,
          filters: filter,
          analytics: false,
          attributesToRetrieve: ["sku"],
        },
      })
      .then(async (res: any) => {
        let url = (defaultPostZip && (!customerStatus?.profile_completed || !customerStatus?.has_addresses))
          ? `/api/ERP/getProductsSKUs/productsSKUs?page=1&page_size=${hitsNumber}&post_zip=${defaultPostZip}`
          : `/api/ERP/getProductsSKUs/productsSKUs?page=1&page_size=${hitsNumber}`;

        if (isVendor) {
          url += showNonActiveProduct ? "&show_non_active=true" : "";
        }
        const arrayOfSKUs = res?.data?.hits?.map((obj: any) => obj.sku);
        if (arrayOfSKUs?.length > 0) {
          const result = await axios.post(
            url,
            {
              data: { product_ids: arrayOfSKUs },
            },
            {
              headers: { sessionid: getCookie("sessionid") },
            }
          );
          const resultData = result?.data?.results;
          setProductData(resultData);
          setIsLoading(false);
          carouselProductsRepository.cache({
            carouselName: carouselName,
            products: [],
          });
        } else {
          setProductData([]);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const addGoogleAnalytics = (event: string) => {
    const productItems: any = [];
    productData?.forEach(function (item: any, index: number) {
      const productInfo = {
        item_id: item?.id?.toString(),
        item_name: item?.name,
        coupon: null,
        currency: "USD",
        discount: 0,
        index: index,
        item_brand: item?.brand_name || item?.brand?.name,
        item_category: item?.sub_category || item?.sub_category_name,
        item_category2: item?.main_category || item?.main_category_name,
        item_list_id: title,
        item_list_name: title,
        item_variant: item?.pack_size,
        price: item?.delivered_price,
      };
      productItems.push(productInfo);
    });
    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event: event,
      ecommerce: {
        items: productItems,
      },
    });
  };

  const fetchProductData = async () => {
    setIsLoading(true);
    let carouselName =
      dataSource.FEATURED_PRODUCT +
      (defaultShipping?.address_ || defaultPostZip);
    let data = carouselProductsRepository.get(carouselName);

    if (data?.length > 0) {
      setProductData(data);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      fetchCarouselData(20, algoliaFilters, carouselName);
    }
  };

  const fetchCrossPromotedProducts = async () => {
    setIsLoading(true);
    let carouselName =
      dataSource.YOU_MIGHT_LIKE + (defaultShipping?.address_ || defaultPostZip);
    let data = carouselProductsRepository.get(carouselName);

    if (data?.length > 0) {
      setProductData(data);
      setIsLoading(false);
    } else {
      try {
        const { data } = await axios.get(
          `/api/products/crossPromotedProducts?slug=${productSlug}`
        );
        const productsSku: any = [];
        let filters = algoliaFilters + (productsSku?.length > 0 ? "AND" : "");
        data?.map((product: any) => {
          productsSku.push(product?.id);
        });
        productsSku?.forEach((sku: any, index: any) => {
          return (filters += `"sku":"${sku}"${
            index !== productsSku?.length - 1 ? " OR " : ""
          }`);
        });
        if (filters !== "") {
          fetchCarouselData(20, filters, carouselName);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const fetchBestSellers = async () => {
    setIsLoading(true);
    let carouselName =
      dataSource.BEST_SELLERS + (defaultShipping?.address_ || defaultPostZip);
    let data = carouselProductsRepository.get(carouselName);

    if (data?.length > 0) {
      setProductData(data);
      setIsLoading(false);
    } else {
      try {
        const path = router.asPath.split("/");
        const brand = path[path.length - 1];
        const { data } = await axios.get(
          `/api/ERP/fabric/productItemCarousel?brand=${brand}`
        );
        const productsSku: any = [];
        let filters = algoliaFilters + (productsSku?.length > 0 ? "AND" : "");
        data?.map((id: any) => {
          productsSku.push(id);
        });
        productsSku?.forEach((sku: any, index: any) => {
          return (filters += `"sku":"${sku}"${
            index !== productsSku?.length - 1 ? " OR " : ""
          }`);
        });
        if (filters !== "") {
          fetchCarouselData(20, filters, carouselName);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const fetchRelatedProducts = async () => {
    setIsLoading(true);
    let carouselName =
      dataSource.RELATED_PRODUCTS +
      mainCategory +
      subCategory +
      (defaultShipping?.address_ || defaultPostZip);
    let data = carouselProductsRepository.get(carouselName);

    if (data?.length > 0) {
      setProductData(data);
      setIsLoading(false);
    } else {
      let filters =
        mainCategory === "Food" || mainCategory === "Non-Food"
          ? subCategory
            ? mainCategory === "Food"
              ? `'Food':'true' AND 'Main category name':'${decodeURIComponent(
                  subCategory as string
                )}' ${algoliaFilters ? `AND ${algoliaFilters}` : ""}`
              : `'Food':'false' AND 'Main category name':'${decodeURIComponent(
                  subCategory as string
                )}' ${algoliaFilters ? `AND ${algoliaFilters}` : ""}`
            : mainCategory === "Food"
            ? `'Food':'true' ${algoliaFilters ? `AND ${algoliaFilters}` : ""}`
            : `'Food':'false' ${algoliaFilters ? `AND ${algoliaFilters}` : ""}`
          : mainCategory === "brands"
          ? ""
          : subCategory
          ? `'businesses':'${decodeURIComponent(
              mainCategory as string
            )}' AND 'Main category name':'${decodeURIComponent(
              subCategory as string
            )}' ${algoliaFilters ? `AND ${algoliaFilters}` : ""}`
          : mainCategory
          ? `'businesses':'${decodeURIComponent(mainCategory)}' ${
              algoliaFilters ? `AND ${algoliaFilters}` : ""
            }`
          : `${algoliaFilters}`
          ? `${algoliaFilters}`
          : "";
      if (filters !== "") {
        fetchCarouselData(10, filters, carouselName);
      } else {
        setIsLoading(false);
      }
    }
  };

  const fetchPopularThisMonth = async () => {
    setIsLoading(true);
    let carouselName =
      dataSource.POPULAR_THIS_MONTH +
      (defaultShipping?.address_ || defaultPostZip);
    let data = carouselProductsRepository.get(carouselName);

    if (data?.length > 0) {
      setProductData(data);
      setIsLoading(false);
    } else {
      try {
        const { data } = await axios.get("/api/ERP/fabric/productItemCarousel");
        let filters = algoliaFilters + (data?.length > 0 ? "AND" : "");
        data?.forEach((sku: any, index: any) => {
          return (filters += `"sku":"${sku}"${
            index !== data?.length - 1 ? " OR " : ""
          }`);
        });
        if (filters !== "") {
          fetchCarouselData(10, filters, carouselName);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const fetchRecommendedSearches = async () => {
    setIsLoading(true);
    let carouselName =
      dataSource.RECOMMENDED_SEARCHES +
      (defaultShipping?.address_ || defaultPostZip);
    let data = carouselProductsRepository.get(carouselName);
    if (data?.length > 0) {
      setProductData(data);
      setIsLoading(false);
    } else {
      try {
        let url = `/api/ERP/common/recommended_search?limit=12`;
        if (
          !guestUser &&
          customerStatus &&
          customerStatus?.customer_type != "undefined" &&
          customerStatus?.customer_type != null
        ) {
          url += `&customer_type=${customerStatus?.customer_type}`;
        }
        const { data } = await axios.get(url);
        let filters = "";
        data?.forEach((product: any, index: any) => {
          return (filters += `"sku":"${product?.id}"${
            index !== data?.length - 1 ? " OR " : ""
          }`);
        });

        if (filters !== "") {
          let recomendedSearchfilters =
            "'For states':'undefined'" +
            `${
              customerStatus?.customer_type
                ? "AND" +
                  "'Visible to':'undefined' OR 'Visible to':'" +
                  customerStatus?.customer_type +
                  "'"
                : customerStatus?.is_authenticated ? "AND 'Visible to':'undefined'" : ""
            }`;
          +`${filters ? "AND" + filters : ""}`;
          fetchCarouselData(10, recomendedSearchfilters, carouselName);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const fetchViewedItems = async () => {
    if (!guestUser) {
      setIsLoading(true);
      try {
        let _url = `/api/ERP/customer/viewedProduct?page_size=6`;
        if (defaultPostZip && (!customerStatus?.profile_completed || !customerStatus?.has_addresses)) {
          _url += `&post_zip=${defaultPostZip}`;
        }
        const response = await axios.get(_url, {
          headers: { sessionId: getCookie("sessionid") },
          data:{page_size: 6}
        });
        setProductData(response?.data?.results.slice(0, 6));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchMixMatchVolumeProducts = () => {
    (async () => {
      setIsLoading(true);
      const results = await MixMatchVolumeProductsRepository.get(productSlug);
      if (results && results.length > 0) {
        setProductData(results);
      } else {
        modalShowSet(false);
        setShowNoOffersModal(true);
      }
      setIsLoading(false);
    })();
  };
  const fetchHaggleRewards = async()=>{
    setIsLoading(true);
    try {
      let _url = `/api/ERP/haggleRewardProducts`;
      if (defaultPostZip && (!customerStatus?.profile_completed || !customerStatus?.has_addresses)) {
        _url += `?post_zip=${defaultPostZip}`;
      }
      const response = await axios.get(_url, {
        headers: { sessionId: getCookie("sessionid") },
      });
      setProductData(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    fetchCarouselData,
    fetchMixMatchVolumeProducts,
    fetchViewedItems,
    fetchRecommendedSearches,
    fetchPopularThisMonth,
    fetchRelatedProducts,
    fetchBestSellers,
    fetchCrossPromotedProducts,
    addGoogleAnalytics,
    fetchProductData,
    fetchHaggleRewards
  };
};

export default useCarousel;
