import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

import TabContent from "./tabContent";
import { CategoryItem } from "./types";

function HeaderMenuDrawer({
  categoryItem,
  title,
  setMenuOpen = () => {}
}: any) {
  const [defaultActiveKey, setDefaultActiveKey] = useState("");
  const item = categoryItem?.map((item: CategoryItem) => {
    if (
      !(
        item?.attributes?.filter(
          (attribute: any) => attribute.name === "isHidden"
        )?.[0]?.value === "true"
      )
    ) {
      return {
        label: `${item?.name}`,
        key: item?.id,
        children: (
          <TabContent content={item} title={title} setMenuOpen={setMenuOpen} />
        ),
        disabled: false
      };
    }
  });
  const calcDefaultActiveKey = () => {
    let count = 0;
    categoryItem?.map((item: CategoryItem) => {
      if (
        !(
          item?.attributes?.filter(
            (attribute: any) => attribute.name === "isHidden"
          )?.[0]?.value === "true"
        )
      ) {
        if (count == 0) {
          setDefaultActiveKey(item.id);
        }
        count++;
      }
    });
  };

  useEffect(() => {
    calcDefaultActiveKey();
  }, []);

  item?.unshift({
    label: `Shop by ${title}`,
    key: "",
    children: <></>,
    disabled: true
  });
  return (
    <>
      {defaultActiveKey && (
        <Tabs
          defaultActiveKey={defaultActiveKey}
          tabPosition={"left"}
          items={item}
        />
      )}
    </>
  );
}
export default HeaderMenuDrawer;
