import { Col, Row, Modal, Form, Select, notification } from "antd";
import Field from "@components/shared/Fields";
import React, { Fragment, useEffect, useState } from "react";
import { normalizePhoneNumber, phoneValidator } from "~/src/libs/utils/phoneValidator";
import CustomButton from "../Button";
import styles from "./styles.module.scss";
import ThankYouModal from "./ThankYouModal";
import { haggleModalProps } from "./types";
import { getText } from "~/src/libs/resources";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import { useRouter } from "next/router";
const HaggleModal = ({title, subTitle, setIsModalOpen, isModalOpen }: haggleModalProps) => {
  const [form] = Form.useForm();
  const router = useRouter();
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(false);
  const [isThankYouModalOpen, setThankYouModalOpen] = useState<boolean>(false);
  const [customerTypes, setCustomerTypes] = useState<any[]>();
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };
  const isMovePage = router?.asPath === '/move'?true:false
  const onFinish = async (values: any) => {
    setSaveLoader(true);
    let response;
    const url = isMovePage? "/api/ERP/move/move_contact_us":"/api/ERP/checkout/scheduleDemo"
    try {
      response = await axios.post(url, {
        headers: { sessionId: getCookie("sessionid") },
        data: {
          first_name: values?.first_name,
          last_name: values?.last_name,
          email: values?.email,
          company_name: values?.company_name,
          phone_number: values?.phone_number,
          business_type: values?.business_type,
        },
      });
      setThankYouModalOpen(true);
      setIsModalOpen(false);
      setSaveLoader(false);
      form.resetFields();
    } catch (error: any) {
      notification.error({
        message: `${
          error["response"]?.data?.detail ||
          getText().General.Messages.SomethingWrongTryAgain
        }`,
        duration: 4,
      });
    }
    finally{
      setIsModalOpen(false);
      setSaveLoader(false);
      form.resetFields();
    }
  };
  const getCustomerTypes = async () => {
    try {
      const response = await axios.get("/api/ERP/customer/types", {
        headers: { sessionid: getCookie("sessionid") },
      });
      const businessTypes: any = [];
      response?.data?.forEach((item: any) => {
        businessTypes?.push({
          value: item?.name,
          label: item?.name,
        });
      });
      setCustomerTypes(businessTypes);
    } catch (error: any) {}
  };
  useEffect(() => {
    getCustomerTypes();
  }, []);
  return (
    <Fragment>
      {isModalOpen && (
        <Modal
          className={styles.haggleModal}
          centered
          open={isModalOpen}
          footer={null}
          onCancel={() => setIsModalOpen(false)}
        >
          <h2 className="font-bolder-h2">{title? title : getText()?.Haggle?.saveMore}</h2>
          <p className="mb-18">{subTitle? subTitle : getText()?.Haggle?.letUsShare}</p>
          <Form
            className={styles.haggleForm}
            form={form}
            onFinish={onFinish}
            onFieldsChange={handleFormChange}
          >
            <Row gutter={[16, 12]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  validateTrigger="onBlur"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                  ]}
                >
                  <Field placeholder="First Name" label="First Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  validateTrigger="onBlur"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                  ]}
                >
                  <Field placeholder="Last Name" label="Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                name="company_name"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: "This field should not be empty" },
                ]}
              >
                <Field placeholder="Company Name" label="Company Name" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                validateTrigger="onBlur"
                name="phone_number"
                rules={[
                  () => ({
                    validator: (_, value) =>
                    phoneValidator(_, value, "phone_number"),
                  }),
                ]}
                normalize={normalizePhoneNumber}
              >
                <Field placeholder="Phone number" label="Phone number" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                validateTrigger="onBlur"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: false,
                    message: "Please input a valid email.",
                  },
                ]}
              >
                <Field placeholder="Email" label="Email" />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                name="business_type"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: "This field should not be empty" },
                ]}
              >
                <Select placeholder="Business Type" options={customerTypes} />
              </Form.Item>
            </Row>
          </Form>
          <div className={`${styles.submitButton} w-100`}>
            <CustomButton
              theme="primary"
              onClick={() => {
                form?.submit();
              }}
              className="py-7 h-auto"
              disabled={disabledSave}
              loading={saveLoader}
            >
              {getText()?.Haggle?.submit}
            </CustomButton>
          </div>
        </Modal>
      )}

      <ThankYouModal
        isThankYouModalOpen={isThankYouModalOpen}
        setThankYouModalOpen={setThankYouModalOpen}
      />
    </Fragment>
  );
};

export default HaggleModal;
