import { memo } from "react";
import { Button } from "antd";
import styles from "./style.module.scss";
import Icon from "@components/shared/Icon";
import { wishListProps } from "./types";
const WishList = ({ favClick, onClick, isWishlist }: wishListProps) => {
  return (
    <Button
      className={styles.heartBtn}
      onClick={onClick}
      aria-label={
        favClick ? "Product added to wishlist" : "Add product to wishlist"
      }
    >
      {<Icon name={isWishlist ? "heart-fill" : "heart"} width={20} height={20} />}
    </Button>
  );
};
export default memo(WishList);
